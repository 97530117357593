import HomeTopSlider from '@components/BannerAds/HomeTopSlider/HomeTopSlider';
import HeaderBanner from '@components/HeaderBanner';
import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import Menu from '@components/Menu';
import SEO from '@components/seo';
import VnrLink from '@components/VnrLink';
import { checkValidAgencyCode } from '@services/user.service';
import { setCookie } from '@utils/cookieUtil';
import { filterOnlyNeededDataPostGroup } from '@utils/postUtils';
import { getSliderBroker } from '@utils/sortBroker';
import { getQueryParams } from '@utils/urlUtil';
import { Col, Row } from 'antd';
import {
  getAllCryptoBrokerDataCache,
  getAllForexBrokerDataCache,
} from 'cache-service/wpBrokerData';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import LazyLoad from 'react-lazyload';
import { DOMAIN } from 'src/constants';
import useTranslate from 'src/hook/useTranslate';

import { getFeaturePostsCache } from '../cache-service/wpGroupPost';
import { getPostHaveCategoriesAndSize } from '../cache-service/wpPost';
import styles from './HomePageStyle.module.scss';
// dynamic
const LayoutPopup = dynamic(() => import('@components/BannerAds/LayoutPopup'), {
  ssr: false,
});
const PopupDisclaimer = dynamic(() => import('@components/popup/PopupDisclaimer'), {
  ssr: false,
});
const BrokerList = dynamic(() => import('@components/BrokerList'), {
  ssr: false,
});
const HomeLeftScroll = dynamic(() => import('@components/BannerAds/HomeLeftScrollUp'), {
  ssr: false,
});
const HomeRightScroll = dynamic(() => import('@components/BannerAds/HomeRightScrollUp'), {
  ssr: false,
});
const SlickBroker = dynamic(() => import('@components/SlickBroker'), {
  ssr: false,
});
const ExchangeSlider = dynamic(() => import('@components/ExchangeSlider'), {
  ssr: false,
});
const Footer = dynamic(() => import('@components/Footer/index'), {
  ssr: false,
});
const PopupUserSentInformation = dynamic(
  () => import('@components/popup/PopupUserSentInformation'),
  {
    ssr: false,
  }
);
const PostGroupCategory = dynamic(() => import('@components/PostGroupCategory/index'), {
  ssr: false,
});
const ProfileInformation = dynamic(
  () => import('@components/ProfileUpdateInfo/ProfileInformationHomepage'),
  {
    ssr: false,
  }
);
const ProfileUpdateInfoPopup = dynamic(
  () => import('@components/ProfileUpdateInfo/ProfileUpdateInfoPopup'),
  {
    ssr: false,
  }
);
const RebatePaymentMethod = dynamic(() => import('@components/RebatePaymentMethod'), {
  ssr: false,
});
const Story = dynamic(() => import('@components/Story'), {
  ssr: false,
});
const VnRebatesShop = dynamic(() => import('@components/VnRebatesShop'), {
  ssr: false,
});
const VnrYoutube = dynamic(() => import('@components/Youtube'), {
  ssr: false,
});
const HomeSliderForeign = dynamic(
  () => import('@components/BannerAds/HomeTopSlider/HomeSliderForeign'),
  {
    ssr: false,
  }
);
/// end dymamic

export const getPostsHaveCategoriesAndSize = async ({ categories, size }) => {
  const posts = await getPostHaveCategoriesAndSize({
    size: size,
    categories: categories,
  });
  return posts;
};

export const getStaticProps = async () => {
  //broker
  const forexBrokersData = await getAllForexBrokerDataCache();
  const cryptoBrokersData = await getAllCryptoBrokerDataCache();
  const newSlickBrokers = getSliderBroker(forexBrokersData);

  let forexBroker = forexBrokersData
    .filter((b) => b.acf.is_ranking_homepage)
    .sort((a, b) => a.acf.ranking_homepage_position - b.acf.ranking_homepage_position)
    .slice(0, 15);
  const newForexBroker = forexBroker.map((b) => {
    return {
      id: b.id,
      languageCode: b.languageCode,
      acf: {
        alt: b.slug,
        manage_by: b.acf.manage_by,
        lever_max: b.acf.lever_max,
        link_register: b.acf.link_register,
        link_rate: b?.acf?.link_rate?.replace('https://vnrebates.net', DOMAIN) || null,
        rebate_money: b?.acf?.rebate_money || null,
      },
      thumbnail: b?.acf?.avatar?.url || null,
      slug: b.slug,
    };
  });

  let cryptoBroker = cryptoBrokersData
    .filter((b) => b.acf.is_ranking_homepage)
    .sort((a, b) => a.acf.ranking_homepage_position - b.acf.ranking_homepage_position)
    .slice(0, 15);
  const newCryptoBroker = cryptoBroker.map((b) => {
    return {
      id: b.id,
      languageCode: b.languageCode,
      acf: {
        alt: b.slug,
        crypto_broker_product: b.acf.crypto_broker_product,
        lever_max: b.acf.lever_max,
        link_register: b.acf.link_register,
        crypto_broker_lever: b.acf.crypto_broker_lever,
        link_rate: b?.acf?.link_rate?.replace('https://vnrebates.net', DOMAIN) || null,
        is_decentralized_exchange: b.acf.is_decentralized_exchange || null,
        rebate_money: b?.acf?.rebate_money || null,
        uu_dai_mo_tai_khoan: b?.acf?.uu_dai_mo_tai_khoan || null,
        crypto_info_rebates: b?.acf?.crypto_info_rebates || null,
      },
      thumbnail: b?.acf?.avatar?.url || null,
      slug: b.slug,
    };
  });

  // group category
  let postsCateTradingCrypto = await getPostsHaveCategoriesAndSize({
    categories: [58, 24],
    size: 5,
  });
  postsCateTradingCrypto = filterOnlyNeededDataPostGroup(postsCateTradingCrypto);

  let postsCateReview = await getPostsHaveCategoriesAndSize({
    categories: [89],
    size: 5,
  });

  let postsCateAnnounceBroker = await getPostsHaveCategoriesAndSize({
    categories: [65],
    size: 5,
  });
  postsCateAnnounceBroker.push({
    id: 'phong-van-doc-quyen-giam-doc-dupoin',
    title: {
      rendered: `<span>VnRebates phỏng vấn độc quyền giám đốc Dupoin Markets</span> <span class='magazine'>Magazine</span>`,
    },
    date: '2024-09-12T08:00:00',
    excerpt: { rendered: '' },
    thumbnail:
      'https://agileops-gafv-dev-videos.s3.amazonaws.com/thumbnail/z5822629879581_3f269b673a8f4fc770cf9cdb0b986116.jpg',
    slug: 'phong-van-doc-quyen-giam-doc-dupoin',
    author: {
      name: 'Đạo Trần',
      slug: 'Dao-Tran',
    },
    categories: [1445, 4],
  });

  postsCateAnnounceBroker.push({
    id: 'phong-van-ceo-connext-tai-su-kien-ra-mat-chinh-thuc-cua-connext-tai-viet-nam',
    title: {
      rendered: `<span>Phỏng vấn CEO Connext tại sự kiện ra mắt chính thức Connext Việt Nam</span> <span class='magazine'>Magazine</span>`,
    },
    date: '2024-09-17T09:30:00',
    excerpt: { rendered: '' },
    thumbnail:
      'https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/z5850013967412_62ab0444df6e75094a6e8d2b2dae0526.jpg',
    slug: 'phong-van-ceo-connext-tai-su-kien-ra-mat-chinh-thuc-cua-connext-tai-viet-nam',
    author: {
      name: 'Đạo Trần',
      slug: 'Dao-Tran',
    },
    categories: [1445, 4],
  });

  let postsCateReviewBroker = postsCateAnnounceBroker
    .concat(postsCateReview)
    .sort((a, b) => b.date.localeCompare(a.date));
  postsCateReviewBroker = filterOnlyNeededDataPostGroup(postsCateReviewBroker);

  let postsCateTradingForex = await getPostsHaveCategoriesAndSize({
    categories: [58, 72],
    size: 5,
  });
  postsCateTradingForex = filterOnlyNeededDataPostGroup(postsCateTradingForex);

  let postsCateTradequy = await getPostsHaveCategoriesAndSize({
    categories: [33],
    size: 5,
  });
  postsCateTradequy = filterOnlyNeededDataPostGroup(postsCateTradequy);

  //menu feature posts
  const featurePostGroups = await getFeaturePostsCache();

  return {
    props: {
      newSlickBrokers,
      newForexBroker,
      newCryptoBroker,
      postsCateTradingForex,
      postsCateTradingCrypto,
      postsCateReviewBroker,
      postsCateTradequy,
      featurePostGroups,
    },
  };
};

const HomePage = (props) => {
  const [isBannerHidden, setIsBannerHidden] = useState(false);
  
  const { locale } = useRouter();
  const { ref } = getQueryParams();
  const translate = useTranslate();

  const checkAgencyCode = async (refCode) => {
    const res = await checkValidAgencyCode(refCode);
    if (res?.data) {
      setCookie('agency-code', refCode, 30);
    }
    return;
  };

  useEffect(() => {
    if (!ref || ref === '') return;

    checkAgencyCode(ref);
  }, [ref]);

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = '/images/1716862789107-hoan_tien_40pt_770x390.webp';
    link.as = 'image';
    link.type = 'image/webp';
    document.head.appendChild(link);
  }, []);

  return (
    <>
      <SEO
        canonical={DOMAIN}
        title={translate.home.title}
        description={translate.home.description}
      />
      <HeaderBanner setIsBannerHidden={setIsBannerHidden} />
      <Menu
        featurePostGroups={props.featurePostGroups}
        translate={translate}
        isBannerHidden={isBannerHidden}
      />

      <h1 className={styles.homepageH1}>
        VnRebates - Kiến thức Trading chuyên sâu Forex, Crypto, Hàng hoá
      </h1>

      <div className={styles.rowSliderWrapper}>
        <Row>
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            className={styles.colSliderBroker}
          >
            <SlickBroker brokers={props.newSlickBrokers} translate={translate} locale={locale} />
          </Col>
        </Row>
      </div>

      <div className={styles.container}>
        <div className={styles.bannerScroll}>{!isMobile && <HomeLeftScroll />}</div>

        <div className={styles.insideContent}>
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 24, lg: 40 },
              { xs: 12, sm: 12, md: 24, lg: 40 },
            ]}
            className={styles.contentGroups}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 17 }}
              lg={{ span: 17 }}
              className={styles.homeTopSlider}
            >
              {locale === 'vi' ? <HomeTopSlider /> : <HomeSliderForeign translate={translate} />}
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0 }}>
              <SlickBroker brokers={props.newSlickBrokers} translate={translate} locale={locale} />
            </Col>

            <Col
              xs={{ span: 0 }}
              sm={{ span: 0 }}
              md={{ span: 7 }}
              lg={{ span: 7 }}
              className={styles.colProRoadAndBanner}
            >
              {!isMobile && (
                <VnrLink to="https://bit.ly/airdrop-T10-2-vnrebates-60-tien-mat">
                  <ImageThumbnailRatio
                    src={translate.home.bannerAcademy}
                    ratio="300:190"
                    alt="banner academy"
                  />
                </VnrLink>
              )}

              {!isMobile && (
                <VnrLink to="/trading-contest/hoc-that-trade-that-nhan-thuong-1000$">
                  <ImageThumbnailRatio
                    src={translate.home.bannerSignal}
                    ratio="300:190"
                    alt="banner signal"
                  />
                </VnrLink>
              )}
            </Col>

            {locale === 'vi' && (
              <Col span={24} className={styles.colProfileInformation}>
                <LazyLoad>
                  <ProfileInformation isOnProfilePage={false} />
                </LazyLoad>
              </Col>
            )}

            {locale === 'vi' && (
              <Col span={24}>
                <LazyLoad>
                  <Story />
                </LazyLoad>
              </Col>
            )}

            <Col
              xs={{ span: 24 }}
              sm={{ span: 0 }}
              md={{ span: 0 }}
              lg={{ span: 0 }}
              className={styles.colProRoadAndBannerMobile}
            >
              <VnrLink to="https://bit.ly/airdrop-T10-2-vnrebates-60-tien-mat">
                <ImageThumbnailRatio
                  src={translate.home.bannerAcademy}
                  ratio="300:190"
                  alt="banner academy"
                />
              </VnrLink>

              <VnrLink to="/trading-contest/hoc-that-trade-that-nhan-thuong-1000$">
                <ImageThumbnailRatio
                  src={translate.home.bannerSignal}
                  ratio="300:190"
                  alt="banner signal"
                />
              </VnrLink>
            </Col>

            <Col span={24}>
              <LazyLoad>
                <VnRebatesShop />
              </LazyLoad>
            </Col>

            {locale === 'vi' && (
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <LazyLoad>
                  <PostGroupCategory
                    title="Kiến thức Trading Forex"
                    posts={props.postsCateTradingForex}
                    linkLoadMore="/forex/kien-thuc"
                    isShowExcerptFirstPost={false}
                  />
                </LazyLoad>
              </Col>
            )}

            {locale === 'vi' && (
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <LazyLoad>
                  <PostGroupCategory
                    title="Kiến thức Trading Crypto"
                    posts={props.postsCateTradingCrypto}
                    linkLoadMore="/forex/kien-thuc"
                    isShowExcerptFirstPost={false}
                  />
                </LazyLoad>
              </Col>
            )}

            {locale === 'vi' && (
              <Col span={24} className={styles.homeMid} id="vnrebatesTv">
                <LazyLoad>
                  <VnrYoutube />
                </LazyLoad>
              </Col>
            )}

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              style={{ backgroundColor: '#e9f3ff' }}
            >
              <div className={styles.backgroundBrokerList}></div>
              <LazyLoad>
                <ExchangeSlider
                  data={[
                    <BrokerList
                      key="forexBrokerList"
                      id="forexBrokerList"
                      brokerList={props.newForexBroker}
                      title={translate.home.forexBrokerListTitle}
                      linkLoadMore="/forex-broker"
                      translate={translate}
                      locale={locale}
                    />,
                    <BrokerList
                      key="cryptoList"
                      id="cryptoBrokerList"
                      brokerList={props.newCryptoBroker}
                      title={translate.home.cryptoBrokerListTitle}
                      linkLoadMore="/crypto-broker"
                      translate={translate}
                      locale={locale}
                    />,
                  ]}
                />
              </LazyLoad>
            </Col>

            {locale === 'vi' && (
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <LazyLoad>
                  <PostGroupCategory
                    title="Phân tích - Nhận định"
                    posts={props.postsCateTradequy}
                    linkLoadMore="/phan-tich-nhan-dinh"
                    isShowExcerptFirstPost={false}
                  />
                </LazyLoad>
              </Col>
            )}

            {locale === 'vi' && (
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <LazyLoad>
                  <PostGroupCategory
                    title="Review- Bonus Sàn Forex/Crypto"
                    posts={props.postsCateReviewBroker}
                    linkLoadMore="/review"
                  />
                </LazyLoad>
              </Col>
            )}

            {locale !== 'vi' && (
              <>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  style={{ backgroundColor: '#e9f3ff' }}
                >
                  <div className={styles.backgroundBrokerList}></div>
                  <RebatePaymentMethod translate={translate} />
                </Col>
              </>
            )}
          </Row>
        </div>

        <div className={styles.bannerScroll}>{!isMobile && <HomeRightScroll />}</div>
      </div>
      <Footer translate={translate} />

      <LayoutPopup />
      <PopupDisclaimer />
      <PopupUserSentInformation />
      <ProfileUpdateInfoPopup />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `  
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "VnRebates",
            "url": "${DOMAIN}",
            "logo": "${DOMAIN}/images/logo.png",
            "contactPoint": [{
                            "@type": "ContactPoint",
                            "telephone": "090 982 55 34",
                            "contactType": "customer service"
                          }],
            "sameAs": [
              "https://www.facebook.com/VnRebates/",
              "https://twitter.com/vnrebates_net",
              "https://www.instagram.com/vnrebates/",
              "https://www.youtube.com/channel/UCpCt75O_kcHwwkW5mgf45Mw",
              "https://www.linkedin.com/company/vnrebates/"
            ]
          }
          `,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `  
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "VnRebates",
            "image": "${DOMAIN}/images/logo.png",
            "@id": "${DOMAIN}/",
            "url": "${DOMAIN}/",
            "telephone": "090 982 55 34",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "235 Hoa Sứ, Phường 7, Phú Nhuận",
              "addressLocality": "Thành phố Hồ Chí Minh",
              "postalCode": "700000",
              "addressCountry": "VN"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 10.7972726,
              "longitude": 106.6882684
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            },
            "sameAs": [
              "https://www.facebook.com/VnRebates/",
              "https://twitter.com/vnrebates_net",
              "https://www.instagram.com/vnrebates/",
              "https://www.youtube.com/channel/UCpCt75O_kcHwwkW5mgf45Mw",
              "https://www.linkedin.com/company/vnrebates/"
            ] 
          }          
          `,
        }}
      />
    </>
  );
};
export default HomePage;
